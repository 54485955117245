<template>
    <v-row no-gutters>
      <v-col>
        <div id="opener-msg-list">
          <!-- app drawer -->
  
           <opener-msg-add-new
            v-model="isAddNewMessageSidebarActive"         
            :edit-message-data="productItem"  
            @refetch-data="fetchOpenerMsgs"
            @save-product-event="saveProductEvent"
          ></opener-msg-add-new>
  
          <v-snackbars
            :objects.sync="snackbars"
            width="500"
            top
            right
            transition="slide-y-transition"
          >
       

            <template v-slot:action="{ close }">
              <v-btn
                icon
                small
                @click="close()"
              >
                <v-icon small>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbars>

          <Image-upload
          v-model="isUploadProductImageActive"
          :data="dataUpload"
          @refetch-data="fetchOpenerMsgs"
          @save-image-event="saveImageEvent"
          :isUploadImageModul="modul"
          :isActive="image_isActive"
          :isImageSize="imageSize"
        />
          <!-- list filters -->
          <v-card
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="[{ 'rounded-t-lg': !$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': userData.role === 'SUPER' }]"
          >
            <v-toolbar
              :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
              flat
              :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
            >
              <v-toolbar-title class="text-h6 font-weight-medium">
                <v-icon
                  large
                  left
                >
                  {{ icons.mdiMessageBulleted }}
                </v-icon>
                Pop-up Mesajları
              </v-toolbar-title>
  
              <v-spacer />
              <v-btn
                class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
                depressed
                x-large
                @click="addOpenerMsg()"
              >
                <span>Yeni Ekle</span>
              </v-btn>
            </v-toolbar>
  
            <!-- table -->
            <v-data-table
              :headers="tableColumns"
              :items="openerMsgListTable"
              :items-per-page="itemsPerPage"
              fixed-header
              :height="dataTableHeight"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
  
              }"
            >
              <!-- Text -->
              <template v-slot:body.prepend>
                <tr style="zoom: 0.8">
                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>  
                  <td class="pa-0">
                    <v-text-field
                      v-model="kategoriSearch"
                      :prepend-inner-icon="icons.mdiMagnify"
                      persistent-placeholder
                      placeholder="Ara"
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>
  
                  <td class="pa-0">
                    <v-text-field
                      filled
                      disabled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>

                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>  

                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>  
                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>  
                  <td class="pa-0">
                    <v-text-field
                      disabled
                      filled
                      class="rounded-0 elevation-0"
                      hide-details
                    ></v-text-field>
                  </td>  
                </tr>
              </template>

                <!-- Resimleri göstermek için slot -->
              
                <template #[`item.dosya_adi`]="{ item }">
                
                  <v-card
                    tile
                    class="rounded d-flex align-center justify-center"
                    width="40"
                    height="40"
                    :color="item.images ? 'white' : 'primary'"
                  >                
                   <v-img
                     
                      v-for="(image, index) in item.images"                     
                      :key="index"
                      :src="'https://portalapi.tukas.com.tr/' +image"
                      width="30" 
                      height="30" 
                      class="mr-2"
                  ></v-img>
                    <v-icon  v-if="item.images.length <= 0">
                      {{ icons.mdiCameraOff }}
                    </v-icon>
                  </v-card>
                </template>


              <template #[`item.is_active`]="{ item }">
                <v-chip
                  :color="item.is_active === '1' ? 'success' : 'warning'"
                  label
                  outlined
                  small
                  style="width: 100%; justify-content: center"
                >
                  {{ item.is_active === '1' ? 'Aktif' : 'Pasif' }}
                </v-chip>
              </template>
  
            
        
              <template #[`item.actions`]="{ item }">
                <v-btn
                  depressed
                  color="nightDark"
                  class="text-capitalize"
                  small
                  @click="editOpenerMsg(item)"
                >
                  <v-icon
                    small
                    left
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  Düzenle
                </v-btn>
                <v-btn
                  plain
                  small
                  class="text-capitalize"
                  @click="deleteOpenerMsg(item)"
                >
                  <v-icon left>
                    {{ icons.mdiDelete }}
                  </v-icon>
                  Sil
                </v-btn>
                <v-tooltip v-if="item.images.length>0" bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="warning"
                      x-small
                      plain
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="productImageDelete(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCameraOff }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resimi Kaldır</span>
                </v-tooltip>
                <v-tooltip v-else bottom color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      color="nightDark"
                      x-small
                      class="rounded-l-0 rounded-r-xl"
                      v-bind="attrs"
                      @click="productImageUpload(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        {{ icons.mdiCamera }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resim Yükle</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import store from '@/store'
  import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiMagnify,
  mdiPlusThick,
  mdiShape,
  mdiSquareEditOutline,
  mdiMessageBulleted,
  } from '@mdi/js'
  import {
  computed, onMounted, ref, watch,
  } from '@vue/composition-api'
  import VSnackbars from 'v-snackbars'
  import Vue from 'vue'
  import openerMsgAddNew from './openerMsgAddNew.vue'
  import ImageUpload from '@/components/ImageUpload.vue'

 
  export default {
    components: {
    openerMsgAddNew,
      VSnackbars,
      ImageUpload,
    },
    setup() {
      
      const snackbars = ref([])
      const isAddNewMessageSidebarActive = ref(false)
      const openerMsgListTable = ref([])    
      const productItem = ref({})
      const kategoriSearch = ref('')
      const tipSearch = ref('')
      const isUploadProductImageActive = ref(false)
      const dataUpload = ref({})
      const userData = JSON.parse(localStorage.getItem('userData'))
      const tableColumns = computed(() => [
        {
          text: 'MesajNo',
          value: 'id',
          width: '60px',
          sortable: false,
        },
      {
        text: 'Resim',
        value: 'dosya_adi',
        width: '100px',
      },
        {
          text: 'Mesaj Başlığı',
          value: 'stext',
           width: '120px',
          filter: f => `${f}`.toLowerCase().includes(kategoriSearch.value.toLowerCase()),
        }, 
        {
          text: 'Mesaj İçeriği',
          value: 'content',
          width: '200px',
        }, 
        {
          text: 'Mesaj Durumu',
          value: 'is_active',
          width: '80px',
          sortable: false,
        }, 
        {
          text: 'Oluşturma Tarihi',
          value: 'create_datetime',
          width: '200px',
          sortable: false,
        }, 
        {
          text: '',
          value: 'actions',
          align: 'right',
          width: '250px',
          sortable: false,
        },
      ])
  
      const idFilter = ref('')
      const totalListTable = ref(0)
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
        itemsPerPage: 15,
      })
      const productTotalLocal = ref([])
  
      const deleteOpenerMsg = item => {
        Vue.swal({
          title: 'Emin misin?',
          text: 'Silme işlemini onaylayın',
          icon: 'question',
          background: '#FF4C51',
          showCloseButton: false,
          showCancelButton: true,
          width: '300px',
          confirmButtonText: 'Onaylıyorum',
          cancelButtonColor: '#FF4C51',
          confirmButtonColor: '#cc3d41',
        }).then(result => {
          if (result.isConfirmed) {
            loading.value = true
            store
              .dispatch('postMethod', {
                method: 'deleteOpenerMsg',
                id: item.id,
              })
              .then(() => {
                snackbars.value.push({
                  message: 'Silindi',
                  color: 'error',
                  timeout: 5000,
                })
  
                fetchOpenerMsgs()
              })
              .catch(e => {
                Vue.swal({
                  title: 'Hata',
                  text: e.message,
                  width: '350px',
                  icon: 'error',
                  background: '#FF4C51',
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonColor: '#cc3d41',
                })
              })
          }
        })
      }
  
      const saveProductEvent = val => {
        snackbars.value.push(val)
      }
  
      const editOpenerMsg = item => {
        console.log(item); // item içeriğini kontrol edin
        productItem.value = item
        isAddNewMessageSidebarActive.value = true
      }
  
      const addOpenerMsg = () => {
        productItem.value = {}
        isAddNewMessageSidebarActive.value = true
      }
  
      const fetchOpenerMsgs = () => {
    const query = {
        options: options.value,
        'search[t.id]': '',
    };

    const method = 'getOpenerMsgs';
    loading.value = true; // Yükleme durumunu başlat

    store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
            if (response.error === 0) {
                openerMsgListTable.value = response.response.result.map(msg => {
                  msg.images = []; // 
                  msg.imagesUploadid = []; //                   
                  return msg;
                });
                
                totalListTable.value = Number(response.response.pagination.totalRec);

                // Her bir mesajın resimlerini yüklemek için döngü
                response.response.result.forEach(msg => {
                    fetchMediaItems(msg.id); // Mesaj ID'sini kullanarak resimleri yükle
                });
            } else {
                console.error('API hatası:', response.msg);
            }
            loading.value = false; // Yükleme durumunu kapat
        })
        .catch(e => {
            loading.value = false; // Yükleme durumunu kapat
            Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
            });
        });
      };
    const fetchMediaItems = (messageId) => {
   
    const method = 'getMediaItems';
    const localid = messageId;
    const modul = 'OpenerMsg';
    
    store.dispatch('postMethod', { localid ,modul , method })
        .then(response => {
            if (response.error === 0) {
                const kresimFiles = response.files.kresim;
                const imageUrls = kresimFiles.map(file => file.dosya_adi);
                const uploadid = kresimFiles.map(file => file.uploadid);
                
                // Resimleri ilgili mesaja ekleme
                const message = openerMsgListTable.value.find(msg => msg.id === messageId);
                if (message) {
                    message.images = imageUrls; // Resim URL'lerini ekle
                    message.imagesUploadid = uploadid; // Resim URL'lerini ekle
                    console.log(message.images)
                    console.log(message.imagesUploadid)
                }
            } else {
                console.error('API hatası:', response.msg);
            }
        })
        .catch(error => {
            console.error('Fetch hatası:', error);
        });
      };

      const saveImageEvent = val => {
      snackbars.value.push(val)
    }
    const productImageDelete = item => {
      
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          debugger
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.imagesUploadid,
            })
            .then(() => {
              debugger
              snackbars.value.push({
                message: `${item.stext} Ürünün Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchOpenerMsgs()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }
    const productImageUpload = item => {
      debugger
      dataUpload.value = item
      isUploadProductImageActive.value = true

      console.log("isUploadProductImageActive",isUploadProductImageActive.value);

      console.log("dataUpload",dataUpload.value);
    }
  
      onMounted(() => {
        fetchOpenerMsgs()
      })
      watch([idFilter, options], () => {
        loading.value = true
        fetchOpenerMsgs()
      })
  
      return {
        tipSearch,
        kategoriSearch,
        productItem,
        editOpenerMsg,
        addOpenerMsg,
        openerMsgListTable,
        tableColumns,
        idFilter,
        totalListTable,
        loading,
        options,
        productTotalLocal,
        isAddNewMessageSidebarActive,
        fetchOpenerMsgs,
        fetchMediaItems,
        deleteOpenerMsg,
        snackbars,
        saveProductEvent,
        userData,
        saveImageEvent,
        productImageDelete,
        productImageUpload,
        isUploadProductImageActive,
        dataUpload,
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiShape,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          mdiMagnify,
          mdiMessageBulleted,
        },
      }
    },
    data() {
      return {
        modul:"OpenerMsg" , 
        image_isActive:1,
        imageSize :[
          {
            bresim:900 ,
            dresim:600,
            oresim:450,
            kresim:50 
          }
        ],  
        itemsPerPage: null,
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
          top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
          footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
        },
      }
    },
  
    watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    },
  
    mounted() {
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.itemsPerPage = Math.ceil(window.innerHeight / 100) + 4
        if (this.userData.role === 'SUPER') {
          this.dataTableHeight = window.innerHeight - 128
        } else {
          this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
        }
  
        const r = document.querySelector(':root')
        r.style.setProperty('--height', `${(this.dataTableHeight - 100) / this.itemsPerPage}px`)
      }, 
    },
  }
  </script>
  
  <style lang="scss">
  :root {
    --height: 30px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: var(--height);
  }
  .v-data-footer__select {
    display: none !important;
  }
  </style>
  