<template>
    <v-navigation-drawer
      :value="isAddNewMessageSidebarActive"
      temporary
      touchless
      floating
      stateless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
      app
      @input="val => $emit('update:is-add-new-message-sidebar-active', val)"
    >

      <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="48" color="secondary">
            <v-icon size="24" class="white--text">{{ formIcon }}</v-icon>
          </v-avatar>
          <div class="text-h6 font-weight-medium ml-3">{{ formTitle }}</div>
        </div>
        <v-btn icon x-small @click="$emit('update:is-add-new-message-sidebar-active', false)">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-sheet>
  
      <form @submit.prevent="submit">
        <validation-observer ref="observer">
          <!-- Başlık -->
          <validation-provider v-slot="{ errors }" name="Başlık" rules="required|max:500">
            <v-text-field
              v-model="messageData.stext"
              filled
              label="Başlık"
              :error-messages="errors"
              hide-details
              class="rounded-0"
            ></v-text-field>
          </validation-provider>
  
          <!-- İçerik -->
          <validation-provider v-slot="{ errors }" name="İçerik" rules="required">
            <v-textarea
              v-model="messageData.content"
              filled
              label="İçerik"
              :error-messages="errors"
              hide-details
              class="rounded-0"
            ></v-textarea>
          </validation-provider>
  
          <!-- Aktif -->
          <validation-provider v-slot="{ errors }" name="Aktif" rules="required">
            <v-checkbox
              v-model="messageData.is_active"
              :label="'Aktif: ' + (messageData.is_active ? 'Evet' : 'Hayır')"
              :error-messages="errors"
              hide-details
              class="rounded-0"
            ></v-checkbox>
          </validation-provider>
  
          <!-- ID (Edit işlemi için) -->
         <!--  <validation-provider v-if="this.editMessageData && Object.keys(this.editMessageData).length > 0" v-slot="{ errors }" name="ID" rules="required">
            <v-text-field v-if="this.editMessageData && Object.keys(this.editMessageData).length > 0"
              v-model="messageData.id"
              filled
              label="ID"
              :error-messages="errors"
              hide-details
              class="rounded-0"
              readonly
            ></v-text-field>
          </validation-provider> -->
        </validation-observer>
  
        <div class="pa-3">
          <v-btn x-large rounded block :loading="loading" color="secondary" @click="submit">
            {{ formButtonText }}
          </v-btn>
        </div>
      </form>
    </v-navigation-drawer>
  </template>
  
  <script>
  import Vue from 'vue';
  import { mdiClose, mdiPlusThick } from '@mdi/js';
  import useAppConfig from '@core/@app-config/useAppConfig';
  
  export default {
    model: {
      prop: 'isAddNewMessageSidebarActive',
      event: 'update:is-add-new-message-sidebar-active',
    },
    props: {
      isAddNewMessageSidebarActive: {
        type: Boolean,
        required: true,
      },
      editMessageData: {
        type: Object,
        default: null,
      },
    },
    setup(props) {
        
      const { isDark } = useAppConfig();
      return {
        isDark,
      };
    },
    data() {
      return {
        loading: false,
        messageData: {
          id: this.editMessageData ? this.editMessageData.id : null,
          stext: this.editMessageData ? this.editMessageData.stext : '',
          content: this.editMessageData ? this.editMessageData.content : '',       
          is_active: this.editMessageData ? Number(this.editMessageData.is_active) === 1 : false,
          
        },
        icons: {
          mdiClose,
          mdiPlusThick,
        },
      };
    },
    computed: {
      formIcon() {
        return this.icons.mdiPlusThick;
      },
      formTitle() {
        return this.editMessageData && Object.keys(this.editMessageData).length > 0
          ? 'Mesajı Düzenle'
          : 'Yeni Pop-Up Mesajı Ekle';
      },
      formButtonText() {
        return this.editMessageData && Object.keys(this.editMessageData).length > 0
          ? 'Güncelle'
          : 'Kaydet';
      },
    },
    watch: {
      isAddNewMessageSidebarActive(val) {
        console.log('is_Active',this.editMessageData.is_active); 

        if (val && this.editMessageData && Object.keys(this.editMessageData).length > 0) {
          this.$nextTick(() => {
            this.messageData = {
              id: this.editMessageData.id,
              stext: this.editMessageData.stext,
              content: this.editMessageData.content,
             is_active: this.editMessageData ? Number(this.editMessageData.is_active) === 1 : false,
            };
          });
          console.log('is_Active2',this.messageData.is_active); 

        }
        if (!val) {
          this.close();
        }
      },
    },
    methods: {
      async submit() {
        debugger
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          Vue.swal({
            title: 'Hata',
            html: 'Lütfen zorunlu alanları doldurunuz',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: true,
          });
        } else {
          try {
            debugger
            const response = await this.$store.dispatch('postMethod', {
              method: 'saveOpenerMsg',
              id: this.editMessageData && this.editMessageData.id ? this.editMessageData.id : null,
              'fieldx[tr][stext]': this.messageData.stext,
              'fieldx[tr][content]': this.messageData.content,
              'field[is_active]': this.messageData.is_active ? 1 : 0,
             
              'field[create_datetime]': this.formatDate(new Date()),
            });
  
            if (response.error === 1) {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              });
            } else {
                this.$emit('refetch-data')
              this.$emit('update:is-add-update-sidebar-active', false)
  
             
              this.$emit('save-product-event', {
                message: `Veri Girişi Başarılı`,
                color: 'success',               
                timeout: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              this.loading = false             
            }
          } catch (error) {
            console.error('Error during saving:', error.message || error);
            Vue.swal({
              title: 'Hata',
              html: 'Bir hata oluştu, lütfen tekrar deneyin.',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: true,
            });
          }
        }
      },
      formatDate(date) {
        const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0'); // Gün, 2 basamaklı olacak
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0'dan başlar, 1 ekle
  return `${year}-${month}-${day}`; // yyyy-dd-mm formatı
      },
      close() {
        this.$refs.observer.reset();
        this.$nextTick(() => {
          this.messageData = {
            id: '',
            stext: '',
            content: '',
            is_active: '',
          };
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Gerekirse buraya stiller ekleyebilirsiniz */
  </style>
  