var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"opener-msg-list"}},[_c('opener-msg-add-new',{attrs:{"edit-message-data":_vm.productItem},on:{"refetch-data":_vm.fetchOpenerMsgs,"save-product-event":_vm.saveProductEvent},model:{value:(_vm.isAddNewMessageSidebarActive),callback:function ($$v) {_vm.isAddNewMessageSidebarActive=$$v},expression:"isAddNewMessageSidebarActive"}}),_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('Image-upload',{attrs:{"data":_vm.dataUpload,"isUploadImageModul":_vm.modul,"isActive":_vm.image_isActive,"isImageSize":_vm.imageSize},on:{"refetch-data":_vm.fetchOpenerMsgs,"save-image-event":_vm.saveImageEvent},model:{value:(_vm.isUploadProductImageActive),callback:function ($$v) {_vm.isUploadProductImageActive=$$v},expression:"isUploadProductImageActive"}}),_c('v-card',{class:[{ 'rounded-t-lg': !_vm.$vuetify.breakpoint.mdAndDown }, { 'rounded-l-xl': _vm.userData.role === 'SUPER' }],attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMessageBulleted)+" ")]),_vm._v(" Pop-up Mesajları ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text",attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.addOpenerMsg()}}},[_c('span',[_vm._v("Yeni Ekle")])])],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.openerMsgListTable,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":_vm.dataTableHeight,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,

          }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticStyle:{"zoom":"0.8"}},[_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"persistent-placeholder":"","placeholder":"Ara","filled":"","hide-details":""},model:{value:(_vm.kategoriSearch),callback:function ($$v) {_vm.kategoriSearch=$$v},expression:"kategoriSearch"}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"filled":"","disabled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1),_c('td',{staticClass:"pa-0"},[_c('v-text-field',{staticClass:"rounded-0 elevation-0",attrs:{"disabled":"","filled":"","hide-details":""}})],1)])]},proxy:true},{key:"item.dosya_adi",fn:function(ref){
          var item = ref.item;
return [_c('v-card',{staticClass:"rounded d-flex align-center justify-center",attrs:{"tile":"","width":"40","height":"40","color":item.images ? 'white' : 'primary'}},[_vm._l((item.images),function(image,index){return _c('v-img',{key:index,staticClass:"mr-2",attrs:{"src":'https://portalapi.tukas.com.tr/' +image,"width":"30","height":"30"}})}),(item.images.length <= 0)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")]):_vm._e()],2)]}},{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"100%","justify-content":"center"},attrs:{"color":item.is_active === '1' ? 'success' : 'warning',"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.is_active === '1' ? 'Aktif' : 'Pasif')+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","color":"nightDark","small":""},on:{"click":function($event){return _vm.editOpenerMsg(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")]),_vm._v(" Düzenle ")],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"plain":"","small":""},on:{"click":function($event){return _vm.deleteOpenerMsg(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]),_vm._v(" Sil ")],1),(item.images.length>0)?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"warning","x-small":"","plain":""},on:{"click":function($event){return _vm.productImageDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resimi Kaldır")])]):_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"nightDark","x-small":""},on:{"click":function($event){return _vm.productImageUpload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCamera)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resim Yükle")])])]}}],null,true)})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }